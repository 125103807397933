import React, { useState, useEffect, useRef } from 'react';
import { TabButton } from '../utility/tabButton.jsx';
import { OnboardingTool } from './onboardingTool.jsx';

const OnboardingTools = ({currentUser}) => {

  const [activeComponent, setActiveComponent] = useState('articleNameChange');

  const renderComponent = (component) => {
    switch (component) {
        case 'articleNameChange':
            return <OnboardingTool currentUser={currentUser} frame={'articleNameChange'}/>;
        default:
            return '';
    }
  };

  return (
      <div className="w3-container">
          <h2>Onboarding Tools</h2>
          <div className="w3-section">
              <div className="w3-bar w3-theme">
                  <TabButton onClick={() => setActiveComponent('articleNameChange')} isActive={activeComponent === 'articleNameChange'}>Artikelnamen ändern</TabButton>
              </div>
          </div>
          {renderComponent(activeComponent)}
      </div>
  );
};

export { OnboardingTools };