import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { API } from '../../../services/apiUrl';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { Modal } from '../../utility/modal';

const ProjectTable = forwardRef(({ projectInfo, currentUser, projectNumber }, ref) => {

    const [loadingInitial, setLoadingInitial] = useState(true);

    const [bauleiterKosten, setBauleiterKosten] = useState('');
    const [calculated, setCalculated] = useState('');
    const [kostenstelle, setKostenstelle] = useState('');
    const [message, setMessage] = useState("");
    const [purchaseInvoices, setPurchaseInvoices] = useState([]);
    const [contractors, setContractors] = useState([]);

    const [subContractorInvoices, setSubContractorInvoices] = useState([]);
    const [steelContractorId, setSteelContractorId] = useState('');
    const [steelContractorInvoices, setSteelContractorInvoices] = useState([]);

    const [selectedSteelContractors, setSelectedSteelContractors] = useState([]);
    const [selectedSubContractors, setSelectedSubContractors] = useState([]);

    const formatNumber = (value) => {
        if (value === null || typeof value === 'undefined') {
          return 'N/A';
        }
    
        if (typeof value === 'string') {
            // Convert string to number
            value = parseFloat(value);
        }
        
        // Round to 2 decimal places and convert to string
        let formattedNumber = value.toFixed(2);
        
        // Split the number into integer and fractional parts
        const parts = formattedNumber.split('.');

        // Add thousands separator
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Join integer and fractional parts with comma
        formattedNumber = parts.join(',');

        return formattedNumber;
    };

    const generatePDF = () => {
        const input = ref.current.cloneNode(true);
        const childNodes = input.childNodes;
        if (childNodes.length >= 4) {
            input.removeChild(childNodes[4]);
        }
        const selectElement = input.querySelector('[name="steelContractor"]');
        const selectedOption = selectElement.querySelector(`option[value="${steelContractorId}"]`);
        if (selectedOption) {
            // Set the selected option
            selectedOption.selected = true;
        }
        const options = {
            margin: [40, 60, 40, 60], // (Optional) Extra margins
            filename: projectNumber+' - '+projectInfo.commission+'.pdf', // (Optional) File name
            html2canvas: { 
                scale: 1, // Adjust HTML to canvas scale
                // Additional options to control quality
                quality: 2, // Quality of the image. 0.1 is very low, 2 is very high. Default is 2.
                dpi: 192, // Dots per inch for the generated PDF. Default is 96.
                letterRendering: true, // Letter rendering can give better results when rendering small elements at small scales. Default is false.
            },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'l' }, // (Optional) jsPDF options
        };
        html2pdf().from(input).set(options).save();
    };

    const onChangeBauleiterKosten = (e) => {
        setBauleiterKosten(e.target.value);
    };

    const onChangeCalculated = (e) => {
        setCalculated(e.target.value);
    };

    const handleSteelContractorChange = (e) => {
        const { value } = e.target;
        if (selectedSteelContractors.includes(value)) {
            setSelectedSteelContractors(selectedSteelContractors.filter(id => id !== value));
        } else {
            setSelectedSteelContractors([...selectedSteelContractors, value]);
        }
    };

    const removeSteelContractor = (index) => {
        const updatedSteelContractors = [...selectedSteelContractors];
        updatedSteelContractors.splice(index, 1);
        setSelectedSteelContractors(updatedSteelContractors);
    };

    const handleSubContractorChange = (e) => {
        const { value } = e.target;
        if (selectedSubContractors.includes(value)) {
            setSelectedSubContractors(selectedSubContractors.filter(id => id !== value));
        } else {
            setSelectedSubContractors([...selectedSubContractors, value]);
        }
    };

    const removeSubContractor = (index) => {
        const updatedSubContractors = [...selectedSubContractors];
        updatedSubContractors.splice(index, 1);
        setSelectedSubContractors(updatedSubContractors);
    };

    const totalProjectCosts = projectInfo.orderItems.reduce((total, item) => {
        let itemTotal = parseFloat(item.unitCostInCompanyCurrency) * parseFloat(item.quantity);
        if (item.childItems) {
          itemTotal += item.childItems.reduce((subtotal, option) => subtotal + parseFloat(option.unitCostInCompanyCurrency) * parseFloat(option.quantity), 0);
        }
        return total + itemTotal;
    }, 0);

    const actualPostQuantity = projectInfo.orderItems.reduce((accumulator, item) => {
        // Check if the item has child items and the category is "59501"
        if (item.childItems && item.childItems.length > 0) {
            const postItems = item.childItems.filter(child => child.articleCategoryId === "59501");
            // Sum the quantities of items with category "59501"
            const postQuantity = postItems.reduce((sum, post) => sum + parseInt(post.quantity), 0);
            const postCount = postItems.length; // Count of items with category "59501"
            
            // Add the sum of quantities and count to the accumulator
            accumulator.totalQuantity += postQuantity;
            accumulator.totalCount += postCount;
        }
        return accumulator;
    }, { totalQuantity: 0, totalCount: 0 });

    useEffect(() => {
        if (selectedSubContractors.length > 0) {
            const filteredInvoices = purchaseInvoices.filter(invoice => selectedSubContractors.includes(invoice.supplierId));
            setSubContractorInvoices(filteredInvoices);
        } else {
            setSubContractorInvoices([]);
        }
    }, [selectedSubContractors, purchaseInvoices]);

    useEffect(() => {
        if (selectedSteelContractors.length > 0) {
            const filteredInvoices = purchaseInvoices.filter(invoice => selectedSteelContractors.includes(invoice.supplierId));
            setSteelContractorInvoices(filteredInvoices);
        } else {
            setSteelContractorInvoices([]);
        }
    }, [selectedSteelContractors, purchaseInvoices]);

    useEffect(() => {
        axios.post(`${API}mkg/projectKostenstelle.php`, {
            currentUser,
            'salesOrderId' : projectInfo.id
        })
        .then(response => {
            if (response.data) {
                setKostenstelle(response.data);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                try {
                    const responseData = JSON.parse(error.response.data);
                    const { status, title } = responseData;
                    setMessage(`Fehler beim Abrufen der Kostenstelle: ${status} - ${title}`);
                } catch (parseError) {
                    console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                }
            } else {
                console.error('Fehler beim Parsen der Antwortdaten:', error);
            }
        });
    }, [projectInfo])

    useEffect(() => {
        axios.post(`${API}mkg/projectPurchaseInvoice.php`, {
            currentUser,
            costCenterId : kostenstelle
        })
        .then(response => {
            // Ladezeit einbauen
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                const filteredData = response.data.filter(item => item.purchaseOrders.length === 0);
                setPurchaseInvoices(filteredData);
                setLoadingInitial(false);
            }
        })
        .catch(error => {
            setLoadingInitial(false);
            if (error.response && error.response.data) {
                try {
                    const responseData = JSON.parse(error.response.data);
                    const { status, title } = responseData;
                    setMessage(`Fehler beim Abrufen der Projektauftrags-Optionen: ${status} - ${title}`);
                } catch (parseError) {
                    console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                }
            } else {
                console.error('Fehler beim Parsen der Antwortdaten:', error);
            }
        });
    }, [kostenstelle])

    useEffect(() => {
        if (purchaseInvoices.length > 0) {
            const uniqueSupplierIds = [...new Set(purchaseInvoices.map(invoice => invoice.supplierId))];
            axios.post(`${API}mkg/projectContractors.php`, {
                currentUser,
                supplierId : uniqueSupplierIds
            })
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    const sortedData = response.data.sort((a, b) => {
                        const companyA = a.company.toLowerCase();
                        const companyB = b.company.toLowerCase();
                        if (companyA < companyB) {
                            return -1;
                        }
                        if (companyA > companyB) {
                            return 1;
                        }
                        return 0;
                    });
                    setContractors(sortedData);
                }
            })
            .catch(error => {
                console.error('Error fetching sub-contractors:', error);
            });
        }
    }, [purchaseInvoices]);

    // useEffect(() => {
    // console.log(projectInfo);
    // console.log(purchaseInvoices);
    // console.log(subContractorInvoices)
    // console.log(steelContractorInvoices)
    // console.log(ref);
    // }, [kostenstelle, purchaseInvoices, subContractorInvoices, steelContractorInvoices, ref, projectInfo]);

    return (
        <>
        <React.Fragment>
        {message && (
        <Modal message={message} setMessage={setMessage}/>
        )}
        </React.Fragment>
        {loadingInitial ? (   
            <React.Fragment>
            <div className="w3-section w3-display-container" style={{height: '200px'}}>
                <div className="w3-display-middle">
                    <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                </div>    
            </div> 
            </React.Fragment>
        ) : (
            <React.Fragment>
            <div className="w3-section">
                <div className="w3-row">
                    <div className="w3-half">Projektbezeichnung:</div>
                    <div className="w3-half">{ projectInfo.commission }</div>
                </div>
                <div className="w3-row">
                <div className="w3-half">Systemleistung / System Performance in KWp:</div>
                <div className="w3-half">{ formatNumber(projectInfo.customAttributes[0]['numberValue']) }</div>
                </div>
                <div className="w3-row">
                <div className="w3-half">Kalkulierte Pfostenanzahl:</div>
                <div className="w3-half">{ formatNumber(projectInfo.customAttributes[1]['numberValue']) }</div>
                </div>
                <div className="w3-row">
                <div className="w3-half">Tatsächliche Pfostenanzahl:</div>
                <div className="w3-half">{ formatNumber((actualPostQuantity.totalQuantity / projectInfo.customAttributes[0]['numberValue']) * 1000) }</div>
                </div>
                <div className="w3-row">
                <div className="w3-half">Stahl-/Zinkunternehmer:</div>
                <div className="w3-half">
                    <select
                        className="w3-border-0" 
                        name="steelContractor"
                        onChange={handleSteelContractorChange}
                        value={0}
                    >
                        <option key={0} value={0}></option>
                        {contractors.map(subContractor => (
                            <option key={subContractor.id} value={subContractor.id}>{subContractor.company}</option>
                        ))}
                    </select>
                    <div>
                        {selectedSteelContractors.map((contractorId, index) => (
                            <div key={index}>
                                {contractors.find(contractor => contractor.id === contractorId)?.company}&nbsp;&nbsp;
                                <i onClick={() => removeSteelContractor(index)} className='w3-small fa fa fa-close' style={{cursor: 'pointer'}}></i>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
                <div className="w3-row">
                <div className="w3-half">Subunternehmer:</div>
                <div className="w3-half">
                    <select
                        className="w3-border-0" 
                        name="subContractor"
                        onChange={handleSubContractorChange}
                        value={0}
                    >
                        <option key={0} value={0}></option>
                        {contractors.map(subContractor => (
                            <option key={subContractor.id} value={subContractor.id}>{subContractor.company}</option>
                        ))}
                    </select>
                    <div>
                        {selectedSubContractors.map((contractorId, index) => (
                            <div key={index}>
                                {contractors.find(contractor => contractor.id === contractorId)?.company}&nbsp;&nbsp;
                                <i onClick={() => removeSubContractor(index)} className='w3-small fa fa fa-close' style={{cursor: 'pointer'}}></i>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="w3-section w3-mobile">
                <table className="w3-table-all w3-bordered w3-border ">
                    <thead>
                        <tr className='w3-light-grey'>
                            <th>Pos.-Nr.</th>
                            <th>Artikelnummer</th>
                            <th>Bezeichnung</th>
                            <th>Menge</th>
                            <th>EK/Einheit</th>
                            <th>EL Gesamt</th>
                        </tr>
                    </thead>
                    <tbody>
                    {projectInfo.orderItems.map((item, index) => {
                    let childItemsTotalCost = 0;
                    if (item.childItems) {
                    childItemsTotalCost = item.childItems.reduce((total, option) => total + parseFloat(option.unitCostInCompanyCurrency * option.quantity), 0);
                    }

                    return (

                        <React.Fragment key={index}>
                            <tr key={index} className='w3-light-grey'>
                                <th>{item.positionNumber}</th>
                                <th>{item.articleNumber}</th>
                                <th>{item.title}</th>
                                <th className='w3-right-align'>{formatNumber(item.quantity)}</th>
                                <th className='w3-right-align'>{formatNumber(item.childItems ? childItemsTotalCost : item.unitCostInCompanyCurrency)}</th>
                                <th className='w3-right-align'>{formatNumber(item.childItems ? childItemsTotalCost * item.quantity : item.unitCostInCompanyCurrency * item.quantity)}</th>
                            </tr>
                            {item.childItems && item.childItems.map((option, i) => (
                                <tr key={`${index}-${i}`} className='w3-white'>
                                    <td>{item.positionNumber + '.' + option.positionNumber}</td>
                                    <td>{option.articleNumber}</td>
                                    <td>{option.title}</td>
                                    <td className='w3-right-align'>{formatNumber(option.quantity)}</td>
                                    <td className='w3-right-align'>{formatNumber(option.unitCostInCompanyCurrency)}</td>
                                    <td className='w3-right-align'>{formatNumber(option.unitCostInCompanyCurrency * option.quantity)}</td>
                                </tr>
                            ))}                    
                        </React.Fragment>
                        )}
                    )}
                    </tbody>
                    <tfoot>
                        <tr className='w3-light-grey'>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th className='w3-right-align' colSpan={'2'}>
                                {formatNumber(totalProjectCosts)}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            </div>
            <div className="w3-section w3-mobile">
                <table className="w3-table-all w3-border">
                    <thead>
                        <tr className='w3-light-grey'>
                            <th style={{width: '30%'}}></th>
                            <th className='w3-right-align' style={{width: '30%'}}>tatsächliche Kosten</th>
                            <th className='w3-right-align' style={{width: '30%'}}>Tatsächlich €/kWp</th>
                            <th className='w3-right-align' style={{width: '10%'}}>kalkuliert</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Größe (kWP):</td>
                            <td className='w3-right-align'>{ formatNumber(projectInfo.customAttributes[0]['numberValue']) }</td>
                            <td className='w3-right-align'></td>
                            <td className='w3-right-align'></td>
                        </tr>
                        <tr>
                            <td>Auftragswert:</td>
                            <td className='w3-right-align'>{ formatNumber(projectInfo.netAmountInCompanyCurrency) }</td>
                            <td className='w3-right-align'>{ formatNumber((projectInfo.netAmountInCompanyCurrency / projectInfo.customAttributes[0]['numberValue'])) }</td>
                            <td className='w3-right-align'></td>
                        </tr>
                        <tr>
                            <td>Kosten Montagesystem:</td>
                            <td className='w3-right-align'>
                                {formatNumber(totalProjectCosts)}
                            </td>
                            <td className='w3-right-align'>
                                {formatNumber(totalProjectCosts / projectInfo.customAttributes[0]['numberValue'])}
                            </td>
                            <td className='w3-right-align'>
                                <input
                                    step=".01"
                                    style={{direction: 'rtl', width: '100%'}} 
                                    name="calculated"
                                    type="number" 
                                    placeholder="kalkuliert"
                                    value={calculated} 
                                    onChange={onChangeCalculated}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Bauleiter:</td>
                            <td className='w3-right-align'>
                                <input
                                    step=".01"
                                    style={{direction: 'rtl'}} 
                                    name="bauleiterKosten"
                                    type="number" 
                                    placeholder="Bauleiterkosten"
                                    value={bauleiterKosten} 
                                    onChange={onChangeBauleiterKosten}
                                />
                            </td>
                            <td className='w3-right-align'>{ formatNumber((bauleiterKosten / projectInfo.customAttributes[0]['numberValue'])) }</td>
                            <td className='w3-right-align'></td>
                        </tr>
                        <tr>
                            <td>SUB:</td>
                            <td className='w3-right-align'>{formatNumber(subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0))}</td>
                            <td className='w3-right-align'>{formatNumber(subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) / projectInfo.customAttributes[0]['numberValue'])}</td>
                            <td className='w3-right-align'></td>
                        </tr>
                        <tr>
                            <td>RG auf Kst-Stelle:</td>
                            <td className='w3-right-align'>{ formatNumber(purchaseInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - steelContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0)) }</td>
                            <td className='w3-right-align'>{ formatNumber((purchaseInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - steelContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0)) / projectInfo.customAttributes[0]['numberValue']) }</td>
                            <td className='w3-right-align'></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Rohertrag:</th>
                            <th className='w3-right-align'>
                                { formatNumber(
                                    projectInfo.netAmountInCompanyCurrency -
                                    totalProjectCosts -
                                    bauleiterKosten -
                                    subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - 
                                    (purchaseInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - steelContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0))
                                )}
                            </th>
                            <th className='w3-right-align'>
                                { formatNumber(
                                    (projectInfo.netAmountInCompanyCurrency -
                                    totalProjectCosts -
                                    bauleiterKosten -
                                    subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - 
                                    (purchaseInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - subContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0) - steelContractorInvoices.reduce((total, invoice) => total + parseFloat(invoice.netAmountInCompanyCurrency), 0)))
                                    / projectInfo.customAttributes[0]['numberValue']
                                )}
                            </th>
                            <th className='w3-right-align'></th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="w3-section">
                <button className='w3-button w3-theme w3-mobile' onClick={generatePDF}>
                    PDF herunterladen
                    <i className='fa fa-file-pdf-o fa-fw w3-margin-left'></i>
                </button>
            </div>
            </React.Fragment>
        )}
        </>
        );

});

export default ProjectTable;