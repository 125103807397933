import React, { useState, useEffect, useRef } from 'react';

const PrintCostsTable = ({ printCosts, setPrintCosts, loading, handleSave, isDirty, setIsDirty, selectedPriceScale, resetChanges }) => {
  const producers = printCosts.producer;
  const attributes = Object.keys(printCosts.attribute);

  const getPriceForProducerAndAttribute = (producerId, attributeId) => {
    const price = printCosts.price.find(
      (p) => p.producer_id === producerId && printCosts.attribute[attributeId].includes(p.attribute_id)
    );
    return price ? price.price : '';
  };

  function TruncatedName({ fullName, maxLength }) {
    const truncatedName = fullName.length > maxLength ? fullName.substring(0, maxLength) + '...' : fullName;
  
    return (
      <div title={fullName}>
        {truncatedName}
      </div>
    );
  }

  const handlePrintCostChange = (e, rowIndex, producerId) => {
    const newValue = parseFloat(e.target.value);
    const updatedPrintCosts = { ...printCosts };

    // Find the attribute corresponding to the row index
    const attribute = attributes[rowIndex];

    // Filter out the objects with an empty price value
    updatedPrintCosts.price = updatedPrintCosts.price.filter(p => p.price !== "");
    
    // Find the price object corresponding to the producer and attribute
    const priceIndex = updatedPrintCosts.price.findIndex(p => p.producer_id === producerId && printCosts.attribute[attribute].includes(p.attribute_id));
    if (newValue === "") {
      // Remove the object from the price array if price is an empty string
      if (priceIndex !== -1) {
          updatedPrintCosts.price.splice(priceIndex, 1);
      }
  } else {
      if (priceIndex !== -1) {
          // Update price if it already exists
          updatedPrintCosts.price[priceIndex].price = newValue;
      } else {
          // If no price exists, create a new price object
          updatedPrintCosts.price.push({
              producer_id: producerId,
              attribute_id: printCosts.attribute[attribute][0], // Using the first attribute_id
              price_scale_id: parseInt(selectedPriceScale),
              price: newValue
          });
      }
  }

    setPrintCosts(updatedPrintCosts);
    setIsDirty(true);
  };

  return (
    <>
    {isDirty && (
      <div className="w3-section w3-bar">
          <button className="w3-button w3-theme w3-right w3-bar-item" type="submit" disabled={loading} onClick={handleSave}>
          {!loading && (
              <i className="fa fa-save fa-fw w3-margin-right"></i>
          )}
          {loading && (
              <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
          )}
          Speichern
          </button>
          <button 
              className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
              style={{
                  pointerEvents: loading ? 'none' : 'auto',
                  marginRight: '8px'
              }}
              onClick={resetChanges}
          >
              {!loading ? (
                  <>
                      <i className='fa fa-save w3-margin-right w3-small'></i>
                  </>
              ) : (
                  <>
                      <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                  </>
              )}
              Zurücksetzen
          </button>
      </div>
      )}
    <div className="w3-section">
    <table className='w3-table-all w3-bordered w3-border'>
      <thead>
        <tr>
          <th></th>
          {producers.map((producer) => (
          <th key={producer.producer_id} className='w3-light-grey w3-border w3-right-align'>
            <TruncatedName fullName={producer.producer_name} maxLength={10}/>
          </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {attributes.map((attribute, index) => (
          <tr key={index}>
            <td className='w3-light-grey w3-border' style={{verticalAlign: 'middle'}}>
              {attribute}
            </td>
            {producers.map((producer) => (
            <td key={producer.producer_id} className='w3-white w3-border'>
              <input 
                type="number"
                className='w3-white w3-input w3-border-0 w3-right-align'
                value={getPriceForProducerAndAttribute(
                  producer.producer_id,
                  attribute
                  )}
                onChange={(e) => handlePrintCostChange(e, index, producer.producer_id)}
                step="0.1"
              />
            </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    </div>
      </>
  );
};

export default PrintCostsTable;