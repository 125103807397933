import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';
import PrintCostsTable from './components/printCostsTable';

const PrintCostEdit = ( {currentUser} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");

    const [priceScales, setPriceScales] = useState([]);
    const [selectedPriceScale, setSelectedPriceScale] = useState(null);

    const [printCosts, setPrintCosts] = useState([]);
    const [originalPrintCosts, setOriginalPrintCosts] = useState([]);

    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setLoadingInitial(true);
        axios.post(`${API}/shirtival/queryPriceScale.php`, {
            currentUser
        })
        .then(response => {
            setPriceScales(response.data);
            setLoadingInitial(false);
        })
        .catch(handleErrorInitial);
    }, []);

    useEffect(() => {
        if (selectedPriceScale) {
            axios.post(`${API}/shirtival/queryPrintCosts.php`, {
                id: selectedPriceScale
            })
            .then(response => {
                const sortedAttributes = Object.keys(response.data.attribute).sort((a, b) => a.localeCompare(b));
                const sortedPrices = response.data.price.map(priceObj => ({
                    ...priceObj,
                    price: parseFloat(priceObj.price)
                }));
                const sortedPrintCosts = {
                    ...response.data,
                    price: sortedPrices,
                    attribute: Object.fromEntries(sortedAttributes.map(key => [key, response.data.attribute[key]]))
                };
                setPrintCosts(sortedPrintCosts);

                const deepCopyPrintCosts = JSON.parse(JSON.stringify(sortedPrintCosts));
                setOriginalPrintCosts(deepCopyPrintCosts);

                setIsDirty(false);
            })
            .catch(handleError);
        }
    }, [selectedPriceScale]);

    const handleError = (error) => {
        const resMessage = (error.response &&
            error.response.data &&
            error.response.data.detail) ||
            error.message ||
            error.toString();
        const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
        const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
        setLoading(false);
        setMessage(errorMessage);
    };

    const handleErrorInitial = (error) => {
        const resMessage = (error.response &&
            error.response.data &&
            error.response.data.detail) ||
            error.message ||
            error.toString();
        const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
        const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
        setLoadingInitial(false);
        setMessage(errorMessage);
    };

    const handlePriceScaleChange = (e) => {
        const selectedPriceScaleId = e.target.value;
        setSelectedPriceScale(selectedPriceScaleId);
    };

    const handleSave = () => {
        setLoading(true);
    
        // Find differences between printCosts and originalPrintCosts prices
        const updatedPrices = printCosts.price.filter(printCost => {
            // Find the corresponding price in originalPrintCosts
            const originalPrintCost = originalPrintCosts.price.find(
                original => original.producer_id === printCost.producer_id &&
                    original.attribute_id === printCost.attribute_id
            );
    
            // Check if originalPrintCost is defined
            if (originalPrintCost) {
                // Compare the price with the original, check if it's different
                return originalPrintCost.price !== printCost.price;
            }
            // If originalPrintCost is undefined, consider it as a new price
            return true;
        });
    
        console.log(updatedPrices);

        axios.post(`${API}/shirtival/updatePrintCosts.php`, {
            updatedPrices
        })
        .then(response => {
            setPrintCosts(response.data);
            setIsDirty(false);
        })
        .catch(handleError);

        setLoading(false);
    };

    const resetChanges = () => {
        setLoading(true)
        setPrintCosts(originalPrintCosts);
        setIsDirty(false);
        setLoading(false)
    };

    useEffect(() => {
        const areTablesEqual = JSON.stringify(printCosts) === JSON.stringify(originalPrintCosts);
        if (areTablesEqual) {
            setIsDirty(false);
        }
    }, [printCosts]);

    return (
        <>
        {message && (
            <Modal 
                message={message}
                setMessage={setMessage}
            />
        )}
        
        {loadingInitial ? (
            <div className="w3-section">Loading...</div>
        ) : ( 
            <>
            <div className="w3-section">           
            <select className='w3-input w3-border' onChange={handlePriceScaleChange} defaultValue={'none'}>
                <option value={selectedPriceScale}>Wähle die Preisstaffel aus</option>
                {priceScales.map(scale => (
                    <option key={scale.id} value={scale.id}>{scale.von ?? 0}{scale.bis ? ` - ${scale.bis}` : ' +'}</option>
                ))}
            </select>
            </div>
            {Object.keys(printCosts).length !== 0 && (
            <PrintCostsTable
                printCosts={printCosts}
                setPrintCosts={setPrintCosts}
                loading={loading}
                handleSave={handleSave}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                selectedPriceScale={selectedPriceScale}
                resetChanges={resetChanges}
            />
            )}
            </>
        )} 
        </>
    );
};

export { PrintCostEdit };


