import React, { useState } from "react";
import { TabButton } from '../utility/tabButton.jsx';
import { ProducerOverwiev } from "./printCosts/producer.jsx";
import { PriceScale } from "./printCosts/priceScale.jsx";
import { PrintCostEdit } from "./printCosts/printCostEdit.jsx";

const ShirtivalPrintCosts = ({ currentUser }) => {

    const [activeComponent, setActiveComponent] = useState(null);

    const renderComponent = (component) => {
        switch (component) {
            case 'overview':
                return <PrintCostEdit currentUser={currentUser} />;
            case 'producer':
                return <ProducerOverwiev currentUser={currentUser} />;
            case 'priceScale':
                return <PriceScale currentUser={currentUser} />;
            default:
                return null;
        }
    };

    return (
        <div className="w3-container">
            <h2>Druckkosten</h2>
            <div className="w3-section">
                <div className="w3-bar w3-theme">
                    <TabButton onClick={() => setActiveComponent('overview')} isActive={activeComponent === 'overview'}>Übersicht</TabButton>
                    <TabButton onClick={() => setActiveComponent('producer')} isActive={activeComponent === 'producer'}>Produzenten</TabButton>
                    <TabButton onClick={() => setActiveComponent('priceScale')} isActive={activeComponent === 'priceScale'}>Preisstaffeln</TabButton>
                </div>
            </div>
            {renderComponent(activeComponent)}
        </div>
    );
};

export { ShirtivalPrintCosts };