import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';

const PriceScale = ( {currentUser} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [expandIndices, setExpandIndices] = useState([]);

    const [priceScales, setPriceScales] = useState({von: '', bis: ''});
    const [deletePriceScalesId, setDeletePriceScalesId] = useState(null);
    const [existingPriceScales, setExistingPriceScales] = useState([]);


    const onChangePriceScales = (e) => {
        const { name, value } = e.target;
        const parsedValue = parseFloat(value); // Parse the value as a float number
        if (!isNaN(parsedValue)) { // Check if the parsed value is a valid number
            setPriceScales(prevState => ({
                ...prevState,
                [name]: parsedValue // Assign the parsed value to the corresponding field
            }));
        } else {
            setPriceScales(prevState => ({
                ...prevState,
                [name]: null // Assign the parsed value to the corresponding field
            }));
        }
    };

    const toggleExpand = (index) => {
        setExpandIndices(prevIndices => {
            if (prevIndices.includes(index)) {
                return prevIndices.filter(i => i !== index);
            } else {
                return [...prevIndices, index];
            }
        });
    };

    useEffect(() => {
        setLoadingInitial(true)

        axios.post(`${API}/shirtival/queryPriceScale.php`, {
            currentUser
        })
        .then(response => {
            setLoadingInitial(false);
            setExistingPriceScales(response.data)
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;

            setLoadingInitial(false);
            setMessage(errorMessage);
        })
    }, []);

    const createPriceScales = (e) => {
        e.preventDefault();
        setLoading(true);
    
        const { von, bis } = priceScales;
    
        if (von < bis || (von === null && bis !== null) || (von !== null && bis === null)) {
            axios.post(`${API}/shirtival/createPriceScale.php`, {
                priceScales,
                currentUser
            })
            .then(response => {
                setLoading(false);
                setMessage('Suc-' + response.data.message);
                setExistingPriceScales(prevPriceScales => [...prevPriceScales, {
                    id: response.data.data.id,
                    von: response.data.data.von,
                    bis: response.data.data.bis
                }]);
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
    
                setLoading(false);
                setMessage(errorMessage);
            })
        } else {
            setLoading(false);
            setMessage('Der "von"-Wert muss kleiner sein als der "bis"-Wert.');
        }
    };

    const confirmDeleteProducer= (priceScalesId) => {
        setDeletePriceScalesId(priceScalesId);
        setMessage(`Ask-Bist du sicher dass du diesen Staffelpreis löschen willst?`);
    };

    const deleteProducer = () => {
        setLoading(true);
        axios.post(`${API}/shirtival/deletePriceScale.php`, {
            priceScalesId: deletePriceScalesId,
            currentUser
        })
        .then(response => {
            setExistingPriceScales(existingPriceScales.filter(existingPriceScales => existingPriceScales.id !== deletePriceScalesId));
            setLoading(false);
            setMessage('Suc-'+response.data);
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.error) ||
                error.message ||
                error.toString();
            setLoading(false);
            setMessage(resMessage);
        });
    };

    return (
        <>
        {message && (
            <Modal 
                message={message}
                setMessage={setMessage}
                onConfirm={deleteProducer} // deleteProducer
            />
        )}
        <div className="w3-section">
        {loadingInitial ? (
            <p>Loading...</p>
        ) : (            
            <div className="w3-border">
                {existingPriceScales
                .sort((a, b) => a.von - b.von)
                .map((existingPriceScales, index) => (                   
                <div
                    className={`w3-bar w3-light-grey w3-border-bottom`}
                    key={existingPriceScales.id}
                >
                    <div className="w3-bar-item" style={{width: '20%'}}>Von:</div>
                    <div className="w3-bar-item" style={{width: '20%'}}>{existingPriceScales.von}</div>
                    <div className="w3-bar-item" style={{width: '20%'}}>Bis:</div>
                    <div className="w3-bar-item" style={{width: '20%'}}>{existingPriceScales.bis}</div>
                    <div className="w3-bar-item w3-button w3-hover-text-theme w3-right">
                        <i 
                            className={`fa fa fa-trash w3-large`}
                            onClick={() => confirmDeleteProducer(existingPriceScales.id)}
                        />
                    </div>
                </div>
                ))}
                <div
                    className={`w3-bar w3-light-grey`}
                    key={'hinzufügen'}
                    onClick={() => toggleExpand('hinzufügen')}
                    style={{cursor: 'pointer', userSelect: 'none'}}
                >
                    <div className="w3-bar-item">Hinzufügen</div>
                    <div className="w3-bar-item w3-button w3-hover-text-theme w3-right">
                        <i
                            className={`fa ${
                                expandIndices.includes('hinzufügen') ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
                            } w3-large`}
                        ></i>
                    </div>
                </div>
                {expandIndices.includes('hinzufügen') && (
                <div className="w3-bar w3-light-grey">
                    <div className={`w3-container w3-padding-16`}>
                        <form onSubmit={ createPriceScales }>
                            <div className="w3-bar-item w3-mobile">
                                <label htmlFor="producer">Von:</label>
                                <input 
                                    className="w3-input w3-margin-bottom w3-light-grey" 
                                    type="number"
                                    name="von"
                                    value={priceScales.von} 
                                    onChange={onChangePriceScales}
                                />
                            </div>
                            <div className="w3-bar-item w3-mobile">
                                <label htmlFor="producer">Bis:</label>
                                <input 
                                    className="w3-input w3-margin-bottom w3-light-grey" 
                                    type="number"
                                    name="bis"
                                    value={priceScales.bis}
                                    onChange={onChangePriceScales}
                                />        
                            </div>
                            <div className="w3-bar-item" style={{width: '100%', padding: '0 0'}}>
                            <button className="w3-button w3-theme w3-margin-top" type="submit" disabled={loading}>
                            {!loading && (
                                <i className="fa fa-save fa-fw w3-margin-right"></i>
                            )}
                            {loading && (
                                <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                            )}
                            <span>Speichern</span>
                            </button>
                            </div>
                        </form>    
                    </div>  
                </div>
                )}
            </div>
        )}
        </div>
        </>
    );
};

export { PriceScale };