import React, { useState, useEffect, useRef, forwardRef } from 'react';
import axios from 'axios';
import { Modal } from '../utility/modal';
import { API } from '../../services/apiUrl';
import ProjectTable from './components/projectTable';

const MkgProject = ({currentUser}) => {

  const [projectNumber, setProjectNumber] = useState('');
  const [projectNumberOptions, setProjectNumberOptions] = useState([]);
  const [projectInfo, setProjectInfo] = useState(null);
  const [chosenProject, setChosenProject] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const tableRef = useRef(null);

  const onChangeProjectNumber = (e) => {
      setProjectNumber(e.target.value);
      setMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (projectNumber.length >= 3) {
        axios.post(`${API}mkg/projectOptions.php`, {
            currentUser,
            projectNumber
        })
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                  setProjectNumberOptions(response.data);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    try {
                        const responseData = JSON.parse(error.response.data);
                        const { status, title } = responseData;
                        setMessage(`Fehler beim Abrufen der Projektauftrags-Optionen: ${status} - ${title}`);
                    } catch (parseError) {
                        console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                    }
                } else {
                    setMessage('Fehler beim Abrufen der Projektauftrags-Optionen:', error);
                }
            });
    }
    if (projectNumber.length != '' && projectNumber.length == 7) {
      setChosenProject(true)
    } else {
      setChosenProject(false)
    }
  }, [projectNumber, currentUser]);

  useEffect(() => {
    if (chosenProject === true) {
        setLoading(true)
        axios.post(`${API}mkg/projectInformation.php`, {
          currentUser,
          projectNumber
        })
        .then(response => {
            if (response.data) {
              setProjectInfo(response.data);
            }
            setLoading(false)
          })
        .catch(error => {
            setLoading(false)
            setChosenProject(false)
            setProjectInfo(null)
            if (error.response && error.response.data) {
                try {
                    const responseData = JSON.parse(error.response.data);
                    const { status, title } = responseData;
                    setMessage(`Fehler beim Abrufen der Projektauftragsinformationen: ${status} - ${title}`);
                } catch (parseError) {
                    console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                }
            } else {
                setMessage('Fehler beim Abrufen der Projektauftragsinformationen', error);
            }
        });
    } else {
      setLoading(false)
      setProjectNumberOptions([])
    }
  }, [chosenProject]);

  return (
    <>
    <div className="w3-container" ref={tableRef}>
      <h2>Projektauswertung</h2>
      <div className="w3-section">
        <form className="w3-row w3-border w3-mobile" onSubmit={ handleSubmit }>
          <div className="w3-col w3-button w3-theme w3-right" style={{width: '50px'}} onClick={ handleSubmit }>
                <i className="w3-large fa fa-search"></i>
          </div>
          <div className='w3-rest'>
              <input 
                className="w3-input w3-border-0" 
                name="project"
                type="text" 
                placeholder="Projektauftragsnummer"
                list="project" 
                value={projectNumber} 
                onChange={onChangeProjectNumber}
              />
              <datalist id="project">
                        {projectNumberOptions.map((option, index) => (
                            <option key={index} value={option.orderNumber}>{option.commission}</option>
                        ))}
              </datalist>
            </div>
        </form>
      </div>
    
    {loading ? (
        <div className="w3-section">
            <p>Loading...</p>
        </div> 
    ) : (
        <>
            {chosenProject && projectInfo ? (
              <>
              <ProjectTable projectInfo={projectInfo} currentUser={currentUser} ref={tableRef} projectNumber={projectNumber}/>
              </>
            ) : (
              <React.Fragment>
                <div className="w3-section">
                {message !== "" ? message : "Die Auftragsnummer konnte nicht gefunden werden."}
                </div>
              </React.Fragment>
            )}
        </>
    )}
    </div>
    </>
  );
};

export { MkgProject };
