import { Modal } from "../utility/modal";
import { API } from "../../services/apiUrl";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const OnboardingTool = ({ currentUser, frame }) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [changedStates, setChangedStates] = useState(false);

    const [eventSource, setEventSource] = useState(false);
    const [data, setData] = useState({tenant: '', apiKey: '', file: ''});

    const resetChanges = () => {
        setLoading(true)
        setData({tenant: '', apiKey: '', file: ''})
        setErrors({});
        setLoading(false)
    };

    const saveChanges = () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const outputContainer = document.getElementById('outputContainer');
            setLoading(true);
            axios.post(`${API}sgbit/onboardingTools.php`, {
                frame,
                data,
                API
            })
            .then(response => {
                outputContainer.innerHTML += response.data + '<br>';
                outputContainer.scrollTop = outputContainer.scrollHeight;
                setLoading(false);
                // setData({tenant: '', apiKey: '', file: ''})
                // setChangedStates(false);
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                    error.message ||
                    error.toString();
                const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
                const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;
                setMessage(errorMessage);
                setLoading(false);
            });
            
            const eventSource = new EventSource(`${API}sgbit/onboardingTool/updates.php`);
            eventSource.onmessage = function(event) {
                outputContainer.innerHTML += event.data + '<br>';
                outputContainer.scrollTop = outputContainer.scrollHeight;
                if (event.data.trim() === 'PROCESS_COMPLETE') {
                    eventSource.close();
                    console.log('SSE connection closed');
                }
            };
        }
    };

    const handleInputChange = (e, keyName) => {
        const { value } = e.target;
        const newValue = value;
        setData({ ...data, [keyName]: newValue });
        setErrors({ ...errors, [keyName]: null });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!data.tenant || data.tenant.trim() === '') {
            newErrors.tenant = 'Tenant darf nicht leer sein';
        }
        if (!data.apiKey || data.apiKey.trim() === '') {
            newErrors.apiKey = 'API-Key darf nicht leer sein';
        }
        if (!data.file || data.file.trim() === '') {
            newErrors.file = 'File darf nicht leer sein';
        }
        return newErrors;
    };

    useEffect(() => {
        const isDataEqual = JSON.stringify(data) === JSON.stringify({tenant: '', apiKey: '', file: ''});
        if (isDataEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [data]);

    useEffect(() => {
        const isDataEqual = JSON.stringify(data) === JSON.stringify({tenant: '', apiKey: '', file: ''});
        if (isDataEqual) {
            setChangedStates(false);
        } else {
            setChangedStates(true);
        }
    }, [eventSource]);

    return (
        <React.Fragment>
            {message && (
            <Modal message={message} setMessage={setMessage}/>
            )}
            {loadingInitial ? (    
                <div className="w3-section w3-display-container" style={{height: '200px'}}>
                    <div className="w3-display-middle">
                        <i className='fa fa-spinner fa-spin w3-margin-right w3-large'></i>
                    </div>    
                </div> 
            ) : (
                <React.Fragment>
                    {changedStates && (
                    <div className="w3-section w3-mobile w3-bar">
                        <button 
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right w3-bar-item`} 
                            style={{
                                pointerEvents: loading ? 'none' : 'auto'
                            }}
                            onClick={saveChanges}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Speichern
                        </button>
                        <button 
                            className={`w3-button ${loading ? 'w3-button-loading' : 'w3-theme'} w3-right`} 
                            style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                marginRight: '8px'
                            }}
                            onClick={resetChanges}
                        >
                            {!loading ? (
                                <>
                                    <i className='fa fa-save w3-margin-right w3-small'></i>
                                </>
                            ) : (
                                <>
                                    <i className='fa fa-spinner fa-spin w3-margin-right w3-small'></i>
                                </>
                            )}
                            Zurücksetzen
                        </button>
                    </div>
                    )}
                    <form className="w3-section w3-mobile w3-bar">
                        <div className={`w3-row-padding w3-bar`} style={{ marginBottom: "16px", padding: '0 0' }}>
                            <div className="w3-half">
                                <input
                                    className={`w3-input w3-border`}
                                    type='text'
                                    value={data['tenant']}
                                    onChange={(e) => handleInputChange(e, 'tenant')}
                                />
                                <div 
                                    className="w3-small w3-white w3-round w3-border"
                                    style={{
                                        position: 'absolute',
                                        padding: '0px 3px',
                                        margin: '0px 7px',
                                        transform: 'translateY(-10px)',
                                    }}> 
                                    Tenant
                                </div>
                                {errors['tenant'] && (
                                    <div 
                                        className="w3-small w3-red w3-panel"
                                        style={{
                                            padding: '0 10px',
                                            marginBottom: '0px'
                                        }}> 
                                        {errors['tenant']}
                                    </div>
                                )}
                            </div>
                            <div className="w3-half">
                                <input
                                    className={`w3-input w3-border`}
                                    type='text'
                                    value={data['apiKey']}
                                    onChange={(e) => handleInputChange(e, 'apiKey')}
                                />
                                <div 
                                    className="w3-small w3-white w3-round w3-border"
                                    style={{
                                        position: 'absolute',
                                        padding: '0px 3px',
                                        margin: '0px 7px',
                                        transform: 'translateY(-10px)',
                                    }}> 
                                    API Key
                                </div>
                                {errors['apiKey'] && (
                                    <div 
                                        className="w3-small w3-red w3-panel"
                                        style={{
                                            padding: '0 10px',
                                            marginBottom: '0px'
                                        }}> 
                                        {errors['apiKey']}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={`w3-row-padding w3-bar`} style={{ marginBottom: "16px" }}>
                            <div className="w3-rest">
                                <input
                                    className={`w3-input w3-border`}
                                    type='file'
                                    value={data['file']}
                                    onChange={(e) => handleInputChange(e, 'file')}                                 
                                />
                                <div 
                                    className="w3-small w3-white w3-round w3-border"
                                    style={{
                                        position: 'absolute',
                                        padding: '0px 3px',
                                        margin: '0px 7px',
                                        transform: 'translateY(-10px)',
                                    }}> 
                                    File
                                </div>
                                {errors['file'] && (
                                    <div 
                                        className="w3-small w3-red w3-panel"
                                        style={{
                                            padding: '0 10px',
                                            marginBottom: '0px'
                                        }}> 
                                        {errors['file']}
                                    </div>
                                )}
                            </div>                     
                        </div>
                    </form>
                    <div className="w3-section w3-mobile w3-bar">
                        <div className={`w3-row-padding w3-bar w3-border`} id="outputContainer">
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );     
};

export { OnboardingTool };