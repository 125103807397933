import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { API } from '../../../services/apiUrl';
import { Modal } from '../../utility/modal';

const ProducerOverwiev = ( {currentUser} ) => {
    
    const [loading, setLoading] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(false);
    const [message, setMessage] = useState("");
    const [expandIndices, setExpandIndices] = useState([]);

    const [producer, setProducer] = useState('');
    const [producerDataValue, setProducerDataValue] = useState('');
    const [producerOptions, setProducerOptions] = useState([]);

    const [deleteProducerId, setDeleteProducerId] = useState(null);

    const [existingProducers, setExistingProducers] = useState([]);


    const onChangeProducer = (e) => {
        setProducer(e.target.value);
        const selectedOption = document.querySelector(`#${e.target.getAttribute('list')} option[value="${e.target.value}"]`);
        if (selectedOption) {
            e.target.setAttribute('data-value', selectedOption.getAttribute('data-value'));
            setProducerDataValue(selectedOption.getAttribute('data-value'));
        } else {
            e.target.removeAttribute('data-value');
            setProducerDataValue('')
        }
    };

    const toggleExpand = (index) => {
        setExpandIndices(prevIndices => {
            if (prevIndices.includes(index)) {
                return prevIndices.filter(i => i !== index);
            } else {
                return [...prevIndices, index];
            }
        });
    };

    useEffect(() => {
        setLoadingInitial(true)

        axios.post(`${API}/shirtival/queryProducer.php`, {
            currentUser
        })
        .then(response => {
            setLoadingInitial(false);
            setExistingProducers(response.data)
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;

            setLoadingInitial(false);
            setMessage(errorMessage);
        })
    }, []);

    useEffect(() => {
        if (producer.length >= 3 && producer != '') {
            axios.post(`${API}shirtival/producerOptions.php`, {
                currentUser,
                producer
            })
            .then(response => {
                if (Array.isArray(response.data) && response.data.length > 0) {
                    setProducerOptions(response.data);
                }
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    try {
                        const responseData = JSON.parse(error.response.data);
                        const { status, title } = responseData;
                        setMessage(`Fehler beim Abrufen der Projektauftrags-Optionen: ${status} - ${title}`);
                    } catch (parseError) {
                        console.error('Fehler beim Parsen der Antwortdaten:', parseError);
                    }
                } else {
                    setMessage('Fehler beim Abrufen der Projektauftrags-Optionen:', error);
                }
            });
        } else {
            setProducerOptions([]);
        }
    }, [producer, currentUser]);

    const createProducer = (e) => {
        e.preventDefault();
        setLoading(true);

        axios.post(`${API}/shirtival/createProducer.php`, { // !!! später die Option ins Zusatzfeld "produktionsplanungOrt" der Instanz schreiben !!!
            id: producerDataValue,
            currentUser
        })
        .then(response => {
            setLoading(false);
            setMessage('Suc-'+response.data.message);
            setExistingProducers(prevProducers => [...prevProducers, {
                producer_id: response.data.data.producer_id,
                producer_weclapp_id: response.data.data.producer_weclapp_id,
                producer_name: response.data.data.producer_name,
                producer_number: response.data.data.producer_number
            }]);

        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.detail) ||
                error.message ||
                error.toString();
            const mysqliError = error.response && error.response.data && error.response.data.mysqliError;
            const errorMessage = mysqliError ? `${resMessage}: ${mysqliError}` : resMessage;

            setLoading(false);
            setMessage(errorMessage);
        })       
    }

    const confirmDeleteProducer= (producerId, producerName) => {
        setDeleteProducerId(producerId);
        setMessage(`Ask-Bist du sicher dass du den Produzenten ${producerName} löschen willst?`);
    };

    const deleteProducer = () => {
        setLoading(true);
        axios.post(`${API}/shirtival/deleteProducer.php`, {
            producerId: deleteProducerId,
            currentUser
        })
        .then(response => {
            setExistingProducers(existingProducers.filter(existingProducers => existingProducers.producer_id !== deleteProducerId));
            setLoading(false);
            setMessage('Suc-'+response.data);
        })
        .catch(error => {
            const resMessage =
                (error.response &&
                error.response.data &&
                error.response.data.error) ||
                error.message ||
                error.toString();
            setLoading(false);
            setMessage(resMessage);
        });
    };

    return (
        <>
        {message && (
            <Modal 
                message={message}
                setMessage={setMessage}
                onConfirm={deleteProducer} // deleteProducer
            />
        )}
        <div className="w3-section">
        {loadingInitial ? (
            <p>Loading...</p>
        ) : (            
            <div className="w3-border">
                {existingProducers
                .sort((a, b) => a.producer_number.localeCompare(b.producer_number))
                .map((existingproducers, index) => (                   
                <div
                    className={`w3-bar w3-light-grey w3-border-bottom`}
                    key={existingproducers.producer_id}
                >
                    <div className="w3-bar-item">({existingproducers.producer_number}) {existingproducers.producer_name}</div>
                    <div className="w3-bar-item w3-button w3-hover-text-theme w3-right">
                        <i 
                            className={`fa fa fa-trash w3-large`}
                            onClick={() => confirmDeleteProducer(existingproducers.producer_id, existingproducers.producer_name)}
                        />
                    </div>
                </div>
                ))}
                <div
                    className={`w3-bar w3-light-grey`}
                    key={'hinzufügen'}
                    onClick={() => toggleExpand('hinzufügen')}
                    style={{cursor: 'pointer', userSelect: 'none'}}
                >
                    <div className="w3-bar-item">Hinzufügen</div>
                    <div className="w3-bar-item w3-button w3-hover-text-theme w3-right">
                        <i
                            className={`fa ${
                                expandIndices.includes('hinzufügen') ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'
                            } w3-large`}
                        ></i>
                    </div>
                </div>
                {expandIndices.includes('hinzufügen') && (
                <div className="w3-bar w3-light-grey">
                    <div className={`w3-container w3-padding-16`}>
                        <form onSubmit={ createProducer }>
                            <label htmlFor="producer">Produzent:</label>
                            <input 
                                className="w3-input w3-margin-bottom w3-light-grey" 
                                type="text"
                                name="producer"
                                list="producer" 
                                value={producer}
                                data-value={producerDataValue} 
                                onChange={onChangeProducer}
                            />
                            <datalist id="producer">
                            {producerOptions.map((option, index) => (
                                <option key={index} value={option.company} data-value={option.id} >{option.supplierNumber}</option>
                            ))}
                            </datalist>                   
                            <button className="w3-button w3-theme w3-margin-top" type="submit" disabled={loading}>
                            {!loading && (
                                <i className="fa fa-save fa-fw w3-margin-right"></i>
                            )}
                            {loading && (
                                <i className="fa fa-spinner fa-spin fa-fw w3-margin-right"></i>
                            )}
                            <span>Speichern</span>
                            </button>
                        </form>    
                    </div>
                </div>
                )}
            </div>
        )}
        </div>
        </>
    );
};

export { ProducerOverwiev };


